import React from "react";

const HeroNoImage = ({ post }) => {
  return (
    <img
      className={`hero-no-image ${post && "hero-no-image-post"}`}
      src={require("../assets/green-blob.svg")}
    />
  );
};

export default HeroNoImage;
