import { Link } from "gatsby";
import React, { useState } from "react";
//import { cn } from "../lib/helpers";

const Header = ({ onHideNav, onShowNav, siteTitle }) => {
  const [showNav, setShowNav] = useState(false);
  return (
    <div className={`header ${showNav && "header--fixed"}`}>
      <div className="header__wrapper">
        <div className={"header__branding"}>
          <Link to="/">Enkeltoggreit.online</Link>
        </div>

        <button
          className="header__toggleNavButton"
          onClick={() => {
            setShowNav(!showNav);
          }}
        >
          <img src={require("../assets/hamburger.svg")} alt="open navigation menu" />
        </button>

        <nav className={`header__nav ${showNav && "header__showNav"}`}>
          <button
            className="header__toggleNavButton"
            onClick={() => {
              setShowNav(!showNav);
            }}
          >
            <img src={require("../assets/cross.svg")} alt="Close navigation menu" />
          </button>

          <ul>
            {showNav && (
              <li>
                <Link to="/">Hjem</Link>
              </li>
            )}
            <li>
              <Link to="/om-meg/">Om Meg</Link>
            </li>

            <li>
              <Link to="/blog/">Blogg</Link>
            </li>
            <li>
              <Link to="/kontakt/">Kontakt</Link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default Header;
