import React from "react";
import Header from "./header";

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle }) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className="content">{children}</div>
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__siteInfo">
          Copyright all rights reserved &copy; {new Date().getFullYear()} <br />
          Built and Designed by
          {` `}
          <a href="https://www.tonyheimark.com">Tony Heimark</a>
        </div>
      </div>
    </footer>
  </>
);

export default Layout;
