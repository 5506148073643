import { Link } from "gatsby";
import React from "react";
import BlogPostPreview from "./blog-post-preview";

function BlogPostPreviewGrid(props) {
  return (
    <div className="preview-grid">
      {props.title && (
        <h2
          className={`preview-grid__headline ${props.page === "index" &&
            "preview-grid__headline--small"}`}
        >
          {props.title}
        </h2>
      )}
      <ul className="preview-grid__grid">
        {props.nodes &&
          props.nodes.map(node => (
            <li key={node.id}>
              <BlogPostPreview {...node} />
            </li>
          ))}
      </ul>
      {props.browseMoreHref && (
        <div className="preview-grid__browse-more-nav">
          <Link className="preview-grid__link" to={props.browseMoreHref}>
            Les flere artikler
          </Link>
        </div>
      )}
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: ""
};

export default BlogPostPreviewGrid;
